import CovidForm from '../model/CovidForm';
const axios = require('axios').default;

const { CosmosClient } = require('@azure/cosmos');

const debug = require('debug');
const log = debug('hdf:cosmos');
const lerror = debug('hdf:errors');

const DatabaseId: string = 'COVIDDatabase';
const FormsContainerId: string = 'COVIDForms';
const FormsLookupsContainerId: string = 'COVIDFormLookups';


export interface COVIDFormLookupSite {
  project: string;
  locations: COVIDFormLookupSiteLocation[];
  mobilenumbers?: string[];
  email?: string[];
  showHotspotQuestion?: boolean;
  state?:string;
  greaterSydneyArea:boolean;
}
export interface COVIDFormLookupSiteLocation {
  name: string;
  mobilenumbers: string[];
  email?: string[];
  state?:string;
  greaterSydneyArea:boolean;
}

export interface COVIDFormLookup {
  sites: COVIDFormLookupSite[];
  staffType: string[];
  formTitle: string;
  formLogoUrl: string;
  showScreenMessage: string;
  defaultSMSNumber: string[];
  privacyStat: string;
  company:string;
  contactedByGov?:string;
  hotspotQuestion?:string;
  hotspotQuestionDefault?:string;
  isCIMIC:boolean;  
  isCPB:boolean;
  isEIC:boolean;
  isPPP:boolean;
  redirect?:{
    redirectUrl:string, 
    states:string[],
    projects:string[]
  }
}

export default class COVIDFormsServiceCosmosdb {
  private _dbclient: any;
  private _db;
  private _formsContainer: any;
  private _formsLookupContainer: any;
  private tokenendpoint = process.env.REACT_APP_COSMOSTOKENENDPOINT;

  public static formNameGlb: string = 'covid19healthform';
  public static appUrlGlb: string = 'hdfdev.cpbcon.com.au';

  private isInitRequired = (): boolean => {
    if (this._dbclient && this._formsContainer) {
      return false;
    }
    return true;
  };

  public init = async (): Promise<void> => {
    if (!this.isInitRequired()) {
      log('Database already initialized...');
      return;
    }
    log('Loading tokens ...');

    await axios
      .post(this.tokenendpoint)
      .then(async response => {
        // handle success
        if (response.data.length <= 0) {
          lerror('No tokens returned, please check the token genertor app or the cleint request.');
          return;
        }

        log('Tokens retrieved.');

        let cosmosClientOption = {};

        let cosmosdbendpoint: string = '';
        let restokens: any = {};
        response.data.forEach(token => {
          restokens[token.resourceId] = token.token;
          cosmosdbendpoint = token.endpoint;
        });
        cosmosClientOption = {
          resourceTokens: restokens,
          endpoint: cosmosdbendpoint,
          connectionPolicy: {
            enableEndpointDiscovery: false
          }
        };

        this._dbclient = new CosmosClient(cosmosClientOption);

        log('Setting up the database...');

        this._db = this._dbclient.database(DatabaseId);

        log('Setting up the database...done!');
        log('Setting up the COVIDForms container...');
        const coResponse = await this._db.containers.createIfNotExists({
          id: FormsContainerId
        });
        this._formsContainer = coResponse.container;
        log('Setting up the COVIDForms container...done!');

        log('Setting up the COVIDFormLookups container...');
        const coLookupResponse = await this._db.containers.createIfNotExists({
          id: FormsLookupsContainerId
        });
        this._formsLookupContainer = coLookupResponse.container;
        log('Setting up the COVIDFormLookups container...done!');
      })
      .catch(function (error) {
        // handle error
        lerror('Error in getting cosmosdb tokek: %O', error);
      });
  };

  public saveFormDate = async (form: CovidForm): Promise<void> => {
    log('Saving health form for project %s...', form.location);

    const { item } = await this._dbclient.database(DatabaseId).container(FormsContainerId).items.upsert(form);
    log(`Form ${item.id} Saved!!!`);
  };

  public getLookupForForm = async (formName?: string, appUrl?: string): Promise<COVIDFormLookup> => {
    const _form = formName || COVIDFormsServiceCosmosdb.formNameGlb;
    let _appUrl = appUrl || COVIDFormsServiceCosmosdb.appUrlGlb;

    if (_appUrl === "localhost"){
      _appUrl = "cpbhealthdeclaration.azurefd.net";
    }
    log('Getting form lookup for Covid Form...', _form);

    let item: any;
    const querySpec = {
      query: 'SELECT * FROM c WHERE c.formName = @formName and c.appUrl = @appUrl',
      parameters: [
        {
          name: '@formName',
          value: _form
        },
        {
          name: '@appUrl',
          value: _appUrl
        }
      ]
    };

    const { resources } = await this._formsLookupContainer.items.query(querySpec).fetchAll();

    if (resources.length === 1) {
      item = resources[0];
      log('Item retrivied: %O', item);
      item.isCIMIC = item.company === "cimic";      
      item.isCPB = item.company === "cpb";
      item.isEIC = item.company === "eic";
      item.isPPP = item.company === "pp";
      return item as COVIDFormLookup;
    }

    lerror('Item not found, maybe an issue with this query? %s', querySpec);

    return {} as COVIDFormLookup;
  };
}
