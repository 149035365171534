import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CovidForm from '../model/CovidForm';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
	Stack,
	IDropdownOption,
	ShimmerElementsGroup,
	ShimmerElementType,
	Fabric,
	Shimmer,
	mergeStyles,
	PrimaryButton,
	DefaultButton,
	Label,
	IStyleFunctionOrObject,
	ILabelStyleProps,
	ILabelStyles,
	ICheckboxStyleProps,
	ICheckboxStyles,
	loadTheme,
	IButtonStyles,
	IChoiceGroupOption,
	DayOfWeek
} from 'office-ui-fabric-react';
import { FormikTextField, FormikCheckbox, FormikDropdown, FormikToggle, FormikChoiceGroup, FormikDatePicker } from 'formik-office-ui-fabric-react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import Fingerprint2 from 'fingerprintjs2';
import COVIDFormsServiceCosmosdb, { COVIDFormLookup, COVIDFormLookupSite } from '../service/Cosmosdb.service';
import moment from 'moment';
import CryptoJS from 'crypto-js';

loadTheme({
	fonts: {
		small: {
			fontSize: '14px'
		},
		medium: {
			fontSize: '16px'
		},
		large: {
			fontSize: '22px',
			fontWeight: '600'
		},
		xLarge: {
			fontSize: '24px',
			fontWeight: '600'
		}
	}
});

enum AppInsEvents {
	FormSubmit = 'FormSubmit',
	ProjectsLoad = 'ProjectsLoad',
	CachedUser = 'CachedUser',
	ProjectInURL = 'ProjectInURL',
	RapidTestResultInURL = 'RapidTestResultInURL'
}

const questionsByCompany = {
	cimic: {
		hasSymptoms: 'Do you have any of the following symptoms: Fever, Cough, Sore throat, Shortness of breath?',
		travel: 'Have you returned from any overseas country in the last 14 days?',
		inContactWithDiagnosed: 'In the past 14 days, have you been in close contact with anyone who has been diagnosed with COVID-19?'
	},
	cpb: {
		hasSymptoms: 'Do you have any of the following symptoms: Fever, Cough, Sore Throat, Shortness of Breath?',
		travel: 'Have you returned from any overseas country in the last 14 days?',
		inContactWithDiagnosed: 'In the past 14 days, have you been in contact with anyone who has COVID-19?',
		inContactTested: 'Have you been in contact with anyone who is currently being tested for COVID-19?',
		diagnosed: 'In the past 14 days, have you been diagnosed with COVID-19?',
		quarantineOrder: 'Have you been directed by a Health Professional, to quarantine for 14 days?'
	},
	nz: {
		hasSymptoms: 'Do you have any of the following symptoms: Fever, Cough, Sore Throat, Shortness of Breath?',
	    diagnosed: 'In the past 7 days, have you been diagnosed with COVID-19?',
		quarantineOrder: 'Have you been directed by a Health Professional, to quarantine for 7 days?'
	}
	};

const getQuestion = (company: string, question: string): string => {
	let ret: string;

	if (questionsByCompany[company]) {
		ret = questionsByCompany[company][question];
	} 
	else if (questionsByCompany[company]=='nz'){
		ret = questionsByCompany['nz'][question];
	}	 	
	else {
		ret = questionsByCompany['cimic'][question];
	}	
	return ret;
};

const reactPlugin = new ReactPlugin();
const cacheKey = 'cpb-healthV1-form-key';
const debug = require('debug');
const lscache = require('lscache');

const log = debug('hdf:form');
const lerror = debug('hdf:errors');

const shimmerWrapperClass = mergeStyles({
	padding: 2,
	selectors: {
		'& > .ms-Shimmer-container': {
			margin: '10px 0'
		}
	}
});
interface FormState {
	isLoading: boolean;
	allSites: COVIDFormLookupSite[];
	defaultSMSNumber?: string[];
	projects: IDropdownOption[];
	locations: IDropdownOption[];
	staffType: IDropdownOption[];
	formTitle: string;
	formLogoUrl: string;
	showScreenMessage: string;
	allowed: boolean;
	warning: boolean;
	confirmed: boolean;
	submittedData?: CovidForm;
	privacyStat?: string;
	company: string;
	contactedByGov?: string;
	hotspotQuestion?: string;
	hotspotQuestionDefault?: string;
	isCIMIC: boolean;	
	isCPB: boolean;	
	isEIC: boolean;
	isPPP: boolean;
	isRAT: boolean;
	isAffectedWorkerNo: boolean;
	rapidTestResult?: string;
	urlProject: string | null;
	urlLocation: string | null;
	redirect?:{
		redirectUrl:string, 
		states:string[],
		projects:string[]
	};
}

interface FormProps {
	appInsights: ApplicationInsights;
}
initializeIcons();
moment.locale('en-AU');

//const ausMobilePattern = /(^(04|\+614|00614)\d{8}$)|(^(\+642|00642)\d{7,9}$)/g;
const ausMobilePattern = /(^(\+6){0,1}\d{7,15}$)/g;

const mobileValidationError = 'Must be a valid mobile number';
const confirmationPersonInfoStyle: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> = {
	root: {
		padding: '1px'
	}
};

const checkBoxAgreement: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> = {
	root: {
		padding: '25px 0px'
	}
};

const buttonStyle: IButtonStyles = {
	root: {
		height: '50px',
		minWidth: '95px'
	}
};

const vaccOptionNSW: IChoiceGroupOption[] = [
	{ key: 'double', text: 'Two Doses of a COVID-19 Vaccine' },
	{ key: 'single', text: 'One Dose of a COVID-19 Vaccine' },
	{ key: 'none', text: 'I am not vaccinated' },
	{ key: 'exemption', text: 'I have a certified medical contraindication and a have been tested for COVID-19 within the preceding 72 hours' }
];

const affectedWorkerOption: IChoiceGroupOption[] = [
	{ key: 'yes', text: 'Yes, I have been tested' },
	{ key: 'no', text: 'No, I have not been tested' },
	{ key: 'na', text: 'Not applicable' }
];

const affectedWorkedLabel: string =
	'If you are an affected worker required to undergo mandatory surveillance COVID-19 testing under the Public Health (COVID-19 Additional Restrictions for Delta Outbreak) Order 2021, please confirm that you have undertaken such testing and that you have evidence of the tests available for inspection on request.';

const affectedWorkedWorningLabel: string =
	'You have indicated you are an affected worker and have not undergone mandatory health surveillance. You are not allowed to enter site until you have undergone mandatory surveillance and receive a negative test result.';

const ratTitle: string = 'Rapid Antigen Test Result';

const threeWeeksAgo: Date = moment().subtract(3, 'weeks').toDate();

class HealthForm extends React.Component<FormProps & RouteComponentProps, FormState> {
	private dbService: COVIDFormsServiceCosmosdb;

	private rapidAntigenTesting = (val: string): boolean => {
		return val !== undefined && val !== null && val.length > 0;
	};

	private schema = yup.object().shape({
		rapidAntigenTesting: yup.string(),
		firstname: yup.string().required('First Name is required'),
		lastname: yup.string().required('Last Name is required'),
		project: yup.string().required('Project is required'),
		location: yup.string().when('project', (project, schema) => {
			return this.getSitesForProject(project).length > 0 ? schema.required('Site is required') : schema;
		}),
		staffType: yup.string().required('Staff type is required'),
		reCaptchaRating: yup.string().required('Captcha not passedRequired'),
		mobile: yup.string().matches(ausMobilePattern, mobileValidationError).required('Required'),
		declaration: yup.mixed().oneOf([true], 'The terms and conditions must be accepted.'),
		privacy: yup.mixed().oneOf([true], 'The Privacy consent must be accepted.'),
		employer: yup.string().when('staffType', { is: ['Other','Sub Contractor'], then: s => s.required('Employer is required when Staff type is Other') }),
		vaccinationStatus: yup.string().when('residentInNSWHODA', {
			is: true,
			then: yup.string().required('Vaccination status is required')
		}),
		vaccFirstDoseDate: yup.date().when('vaccinationStatus', {
			is: 'single',
			then: yup.date().required('Date Administered is required')
		})
	});
	private initialForm: CovidForm;

	constructor(props) {
		super(props);

		//example http://localhost:3000/?project=Integrated Nepean Hospital&site=Construction Office&rat=1k0PS5UPZsr0u4/b3zI1sw==
		const urlparams: URLSearchParams = new URLSearchParams(this.props.location.search);
		const rapidAntigenTestingUrl = urlparams.get('rat');
		const isRAT: boolean = rapidAntigenTestingUrl !== undefined && rapidAntigenTestingUrl !== null && rapidAntigenTestingUrl.length > 0;


		this.initialForm = new CovidForm();
		const cachedUser: any = lscache.get(cacheKey);
		if (cachedUser) {
			this.initialForm.firstname = cachedUser.firstname.replace(/[^\x20-\x7E]+/g, '');
			this.initialForm.lastname = cachedUser.lastname.replace(/[^\x20-\x7E]+/g, '');
			this.initialForm.location = cachedUser.location.replace(/[^\x20-\x7E]+/g, '');
			this.initialForm.project = cachedUser.project.replace(/[^\x20-\x7E]+/g, '');
			this.initialForm.staffType = cachedUser.staffType.replace(/[^\x20-\x7E]+/g, '');
			this.initialForm.mobile = cachedUser.mobile.replace(/[^\x20-\x7E]+/g, '');
			this.initialForm.employer = cachedUser.employer.replace(/[^\x20-\x7E]+/g, '');
			this.props.appInsights.trackEvent({ name: AppInsEvents.CachedUser });

			if (cachedUser.projectVisisted && !isRAT) {
				this.initialForm.projectVisited = cachedUser.projectVisisted.replace(/[^\x20-\x7E]+/g, '');
			}

			if (cachedUser.vaccinationStatus && !isRAT) {
				this.initialForm.vaccinationStatus = cachedUser.vaccinationStatus.replace(/[^\x20-\x7E]+/g, '');
			} else {
				this.initialForm.vaccinationStatus = 'na';
			}

			this.initialForm.affectedWorker = 'na';
		} else {
			this.initialForm.vaccinationStatus = 'na';
			this.initialForm.affectedWorker = 'na';
		}

		const prjUrl = urlparams.get('project');
		const siteUrl = urlparams.get('site');

		if (prjUrl && prjUrl != null) {
			log('setting project form url: %s', prjUrl);
			this.initialForm.project = prjUrl.replace(/[^\x20-\x7E]+/g, '');
			this.props.appInsights.trackEvent({ name: AppInsEvents.ProjectInURL });
		}

		if (siteUrl && siteUrl != null) {
			log('setting site form url: %s', siteUrl);
			this.initialForm.location = siteUrl.replace(/[^\x20-\x7E]+/g, '');
		} else {
			if (prjUrl && prjUrl != null) {
				this.initialForm.location = undefined;
			}
		}

		if (rapidAntigenTestingUrl && rapidAntigenTestingUrl != null) {
			if (this.initialForm.project) {
				const testReslEncr = rapidAntigenTestingUrl.replace(/[^\x20-\x7E]+/g, '');
				const pk = `${this.initialForm.location ? this.initialForm.location.replace(/\s+/g, '_').toLowerCase() : ''}_${this.initialForm.project
					.replace(/\s+/g, '_')
					.toLowerCase()}`;

				log('setting Rapid test result form url: %s', rapidAntigenTestingUrl);
				this.initialForm.rapidAntigenTesting = CryptoJS.AES.decrypt(testReslEncr, pk).toString(CryptoJS.enc.Utf8).toUpperCase();
				this.props.appInsights.trackEvent({ name: AppInsEvents.RapidTestResultInURL });
			}
		}

		this.dbService = new COVIDFormsServiceCosmosdb();

		this.state = {
			isLoading: true,
			allSites: [],
			projects: [],
			locations: [],
			staffType: [],
			formTitle: '',
			formLogoUrl: '',
			showScreenMessage: '',
			company: 'cpb',
			confirmed: false,
			allowed: false,
			warning: false,
			isCIMIC: false,			
			isCPB: true,			
			isEIC: false,
			isPPP: false,
			isRAT: isRAT,
			isAffectedWorkerNo: false,
			urlProject: (prjUrl && prjUrl != null) ? prjUrl.replace(/[^\x20-\x7E]+/g, '') : null,
			urlLocation: (siteUrl && siteUrl != null) ? siteUrl.replace(/[^\x20-\x7E]+/g, '') : null
		};
	}

	componentDidMount() {
		this.dbService.init().then(() => {
			this.props.appInsights.startTrackEvent(AppInsEvents.ProjectsLoad);

			this.dbService
				.getLookupForForm(COVIDFormsServiceCosmosdb.formNameGlb, window.location.hostname || window.location.host)
				.then((lookupdata: COVIDFormLookup) => {
					this.props.appInsights.stopTrackEvent(AppInsEvents.ProjectsLoad);

					let _projects: IDropdownOption[] = [];
					lookupdata.sites.forEach(val => {
						_projects.push({ key: val.project, text: val.project });
					});

					let _staffType: IDropdownOption[] = [];
					lookupdata.staffType.forEach(val => {
						_staffType.push({ key: val, text: val });
					});

					if (lookupdata.redirect && this.state.urlProject && this.state.urlProject !== null ){
						const newUrl = `${lookupdata.redirect.redirectUrl}${window.location.pathname}${window.location.search}`;
						log("Redirect url %s", newUrl);
						if (lookupdata.redirect.projects.indexOf(this.state.urlProject?.toLowerCase()) > -1) window.location.replace(newUrl);
						const selectedProject = lookupdata.sites.find(s => s.project.toLowerCase() === this.state.urlProject?.toLowerCase());

						if (selectedProject && this.state.urlLocation && this.state.urlLocation !== null){
							const selectedLocation = selectedProject.locations.find(l => l.name.toLowerCase() === this.state.urlLocation?.toLowerCase());
							if (selectedLocation && selectedLocation.state && lookupdata.redirect.states.indexOf(selectedLocation.state.toLowerCase()) > -1) window.location.replace(newUrl);
						}

						if (selectedProject && selectedProject.state && lookupdata.redirect.states.indexOf(selectedProject.state.toLowerCase()) > -1) window.location.replace(newUrl);
					}

					this.setState({
						allSites: lookupdata.sites,
						projects: _projects,
						staffType: _staffType,
						formTitle: lookupdata.formTitle,
						formLogoUrl: lookupdata.formLogoUrl,
						showScreenMessage: lookupdata.showScreenMessage,
						defaultSMSNumber: lookupdata.defaultSMSNumber,
						privacyStat: lookupdata.privacyStat,
						company: lookupdata.company,
						contactedByGov: lookupdata.contactedByGov,
						isLoading: false,
						isCIMIC: lookupdata.isCIMIC,						
						isCPB: lookupdata.isCPB,						
						isEIC: lookupdata.isEIC,
						isPPP: lookupdata.isPPP,
						hotspotQuestion: lookupdata.hotspotQuestion,
						hotspotQuestionDefault: lookupdata.hotspotQuestionDefault,
						redirect: lookupdata.redirect
					});
				})
				.catch(error => {
					lerror(error);
					this.props.appInsights.trackException({ exception: new Error(error) });
				});
		});
	}

	private submiteForm = (values: CovidForm, helper: FormikHelpers<CovidForm>): void => {
		this.props.appInsights.startTrackEvent(AppInsEvents.FormSubmit);

		const endDate = new Date();
		values.submissionTime = (endDate.getTime() - values.date.getTime()) / 1000;

		Fingerprint2.get({}, (components: Fingerprint2.Component[]) => {
			const fpvalues: Fingerprint2.Component[] = components.map((component: Fingerprint2.Component) => {
				return component.value;
			});

			const murmur: string = Fingerprint2.x64hash128(fpvalues.join(''), 31);
			log('Fingerprint:%s', murmur);
			values.deviceId = murmur;

			if (!values.location) {
				values.location = 'none';
			}

			if (values.staffType !== 'Other') {
				values.employer = 'none';
			}

			if (this.state.contactedByGov && this.state.contactedByGov.length > 0 && !values.contactedByGov) {
				values.contactedByGov = false;
			}
			values.company = this.state.company;

			const cacheObj = {
				firstname: values.firstname,
				lastname: values.lastname,
				mobile: values.mobile,
				project: values.project,
				location: values.location,
				staffType: values.staffType,
				employer: values.employer,
				projectVisited: values.projectVisited,
				vaccinationStatus: values.vaccinationStatus
			};
			lscache.set(cacheKey, cacheObj, process.env.REACT_APP_CACHEEXPMINS);
			if (this.isPersonAllowed(values)) {
				values.result = 'granted';
			}

			if (values.project) {
				const contacts = this.getContactsForProject(values.project);
				values.projectMobiles = contacts.mobiles;
				values.projectEmail = contacts.email;
			}
			if (values.location && values.project) {
				const contacts = this.getContactsForSite(values.project, values.location);

				values.siteMobiles = contacts.mobiles;
				values.siteEmail = contacts.email;
			}

			log('%O', values);
			this.dbService
				.saveFormDate(values)
				.then(() => {
					this.props.appInsights.stopTrackEvent(AppInsEvents.FormSubmit);

					let cpbNswVaccWarn: boolean = false;

					if (this.state.isCPB && values.residentInNSWHODA) {
						if (values.vaccinationStatus === 'single') {
							cpbNswVaccWarn = true;
						}
					}

					this.setState({
						allowed: this.isPersonAllowed(values),
						warning: values.inContactTested || values.visitedHotspot || values.rapidAntigenTesting === 'INVALID' || cpbNswVaccWarn,
						confirmed: true,
						submittedData: values,
						isAffectedWorkerNo: values.affectedWorker === 'no',
						rapidTestResult: values.rapidAntigenTesting
					});
				})
				.catch(error => {
					lerror(error);
					this.props.appInsights.trackException({ exception: new Error(error) });
				});
		});
	};

	private getSitesForProject = (project?: string): IDropdownOption[] => {
		let _ret: IDropdownOption[] = [];
		const selectProject = this.state.allSites.find((site: COVIDFormLookupSite) => {
			return site.project === project;
		});
		selectProject?.locations.forEach(val => {
			_ret.push({ key: val.name, text: val.name });
		});

		return _ret;
	};

	render() {
		return this.state.confirmed ? (
			this.renderFinalCheck()
		) : this.state.isLoading ? (
			<Fabric className={shimmerWrapperClass}>
				<Shimmer customElementsGroup={this._getCustomElementsExampleOne()} width={350} />
				<Shimmer customElementsGroup={this._getCustomShimmerElementsThree()} width={'90%'} />
			</Fabric>
		) : (
			<Formik
				initialValues={this.initialForm}
				onSubmit={this.submiteForm}
				validationSchema={this.schema}
				validateOnChange={true}
				validateOnBlur={false}
				validateOnMount={true}
				render={(formProps: FormikProps<CovidForm>) => {
					log('Validation Errors: %o', formProps.errors);

					const selectedProject = this.state.allSites.find(s => s.project === formProps.values.project);
					let isGreaterSydneyArea: boolean = selectedProject && selectedProject.greaterSydneyArea ? true : false;
					if (selectedProject && formProps.values.location) {
						const site = selectedProject.locations.find(l => l.name === formProps.values.location);
						if (site && site.greaterSydneyArea) {
							isGreaterSydneyArea = site.greaterSydneyArea;
						}
					}
					
					/*let selectedState: string | undefined = selectedProject?.state;
					if (selectedProject && formProps.values.location) {
						const site = selectedProject.locations.find(l => l.name === formProps.values.location);
						//if (site?.state) selectedState = site?.state;
						if (site && site.state) {
							selectedState = site.state;
						}
					}
                     */
					let selectedState = selectedProject?.state ?? 'nsw';
					if (selectedProject && formProps.values.location) {
						const site = selectedProject.locations.find(l => l.name === formProps.values.location);
						if (site?.state) selectedState = site?.state;
					}
					

					const sitesOptions = this.getSitesForProject(formProps.values.project);
					const showHotspotQuestion: boolean = this.state.allSites.find(p => p.project === formProps.values.project)?.showHotspotQuestion || false;

					const showSite = sitesOptions.length > 0;
					if (!showSite && formProps.values.location !== 'none') {
						log('Setting location to none.');
						formProps.setFieldValue('location', 'none', false);
					} else if (showSite && formProps.values.location === 'none') {
						log('Setting location to undefined.');
						formProps.setFieldValue('location', undefined, true);
						formProps.setFieldTouched('location', true, true);
						formProps.setFieldError('location', 'Site is required');
					} else if (showSite && !formProps.values.location && !formProps.errors.location) {
						log('Trigger location validation');
						//formProps.setFieldTouched('location', true, true);
						formProps.setFieldError('location', 'Site is required');
					} else if (showSite && sitesOptions.length === 1 && formProps.values.location !== sitesOptions[0].key) {
						formProps.setFieldValue('location', sitesOptions[0].key, true);
						formProps.setFieldTouched('location', true, true);
					}

					if (!this.state.isRAT && isGreaterSydneyArea && formProps.values.vaccinationStatus !== 'single' && formProps.values.vaccFirstDoseDate) {
						formProps.setFieldValue('vaccFirstDoseDate', undefined, true);
						formProps.setFieldTouched('vaccFirstDoseDate', true, true);
					}

					return (
						<Form onSubmit={formProps.handleSubmit}>
							<div className="ms-Grid" dir="ltr">
								<div className="ms-Grid-row">
									<div className="ms-Grid-col ms-lg12">
										<Stack tokens={{ childrenGap: 8 }} key="CovidFormTitle" verticalFill={true} verticalAlign="center">
											<div style={{ maxWidth: 160 }}>
												<img alt="cpblogo" style={{ width: '100%' }} src={this.state.formLogoUrl}></img>
											</div>
											<div className="formTitle">{this.state.isRAT ? ratTitle : this.state.formTitle}</div>
											<Label>Please complete this online form everyday</Label>
										</Stack>
										<Stack tokens={{ childrenGap: 12 }} key="CovidForm">
											<Field name="project">
												{field => <FormikDropdown {...field} disabled={formProps.isSubmitting || this.state.isRAT} label="Project" required={true} options={this.state.projects} />}
											</Field>
											{showSite && (
												<Field name="location">
													{field => <FormikDropdown {...field} disabled={formProps.isSubmitting || this.state.isRAT} label="Site" required={true} options={sitesOptions} />}
												</Field>
											)}
											<Field name="staffType">
												{field => <FormikDropdown {...field} disabled={formProps.isSubmitting} label="Staff Type" required={true} options={this.state.staffType} />}
											</Field>
											{(formProps.values.staffType === 'Other' || formProps.values.staffType ===  'Sub Contractor')  && (
												<Field name="employer">
													{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="Employer Name (Required if Other)" required={true} />}
												</Field>
											)}
											<Field name="firstname">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="First Name" required={true} />}</Field>
											<Field name="lastname">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="Last Name" required={true} />}</Field>
											<Field name="mobile">
												{field => (
													<FormikTextField
														{...field}
														disabled={formProps.isSubmitting}
														label="Mobile Number"
														placeholder="Please enter a valid mobile number"
														required={true}
														onGetErrorMessage={this._getErrorMessageForMobile}
													/>
												)}
											</Field>
											{!this.state.isRAT && (
												<>	{(selectedState?.toLowerCase() !== 'nz' && selectedState?.toLowerCase() !== 'wellington') && (												
													<Field name="hasSymptoms">
														{field => (
															<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestion(this.state.company, 'hasSymptoms')} />
														)}
													</Field>
												     )}
													{(selectedState?.toLowerCase() !== 'nz' && selectedState?.toLowerCase() !== 'wellington') && (
													<Stack tokens={{ childrenGap: 5 }} key="CovidForm">
														<Label>Which Symptom?</Label>
														<Field name="symptoms.fever">
															{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Fever" />}
														</Field>
														<Field name="symptoms.cough">
															{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Cough" />}
														</Field>
														<Field name="symptoms.soreThroat">
															{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Sore throat" />}
														</Field>
														<Field name="symptoms.shortnessOfBreath">
															{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Shortness of breath" />}
														</Field>
													</Stack>
													)}
													{(selectedState?.toLowerCase() !== 'nz' && selectedState?.toLowerCase() !== 'wellington') && (
													<Field name="inContactWithDiagnosed">
														{field => (
															<FormikToggle
																{...field}
																disabled={formProps.isSubmitting}
																onText="Yes"
																offText="No"
																label={getQuestion(this.state.company, 'inContactWithDiagnosed')}
															/>
														)}
													</Field>
													)}
													{this.state.isCPB &&  (
														(selectedState?.toLowerCase() !== 'nz' && selectedState?.toLowerCase() !== 'wellington')?
														
														<Field name="diagnosed">
															{field => (
																<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestion(this.state.company, 'diagnosed')} />
															)}
														</Field>
														:<Field name="diagnosed">
														{field => (
															<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestion('nz', 'diagnosed')} />
														)}
													</Field>
													)}
													{this.state.isCPB && (
														(selectedState?.toLowerCase() !== 'nz' && selectedState?.toLowerCase() !== 'wellington')?
														<Field name="quarantineOrder">
															{field => (
																<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestion(this.state.company, 'quarantineOrder')} />
															)}
														</Field>:<Field name="quarantineOrder">
															{field => (
																<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestion('nz', 'quarantineOrder')} />
															)}
														</Field>
													)}
													{this.state.contactedByGov && this.state.contactedByGov.length > 0 && (
														<Field name="contactedByGov">
															{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={this.state.contactedByGov} />}
														</Field>
													)}
													{(this.state.hotspotQuestion || this.state.hotspotQuestionDefault) && (selectedState?.toLowerCase() !== 'nz' && selectedState?.toLowerCase() !== 'wellington') && (
														<Field name="visitedHotspot">
															{field => (
																<FormikToggle
																	{...field}
																	disabled={formProps.isSubmitting}
																	onText="Yes"
																	offText="No"
																	label={showHotspotQuestion ? this.state.hotspotQuestion : this.state.hotspotQuestionDefault}
																/>
															)}
														</Field>
													)}
													{this.state.isCPB && isGreaterSydneyArea && (
														<>
															<Field name="projectVisited">
																{field => (
																	<FormikTextField
																		{...field}
																		disabled={formProps.isSubmitting}
																		label="Construction sites visited in the last 24 hours"
																		placeholder="Please enter all the construction sites you visited in the last 24 hours"
																		multiline
																	/>
																)}
															</Field>
															<Field name="affectedWorker">
																{field => <FormikChoiceGroup {...field} defaultSelectedKey="na" options={affectedWorkerOption} label={affectedWorkedLabel} required={true} />}
															</Field>
															<Field name="residentInNSWHODA">
																{field => (
																	<FormikToggle
																		{...field}
																		disabled={formProps.isSubmitting}
																		onText="Yes"
																		offText="No"
																		label="Do you reside in one of the NSW Health Order Area of Concerns"
																	/>
																)}
															</Field>
															<Field name="permitByServiceNSW">
																	{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label="Do you hold a current permit issued by Service NSW?" />}
																</Field>
															<Label styles={{ root: { fontSize: 20 } }}>Vaccination Status</Label>
															<Field name="vaccinationStatus">
																{field => (
																	<FormikChoiceGroup
																		{...field}
																		options={vaccOptionNSW}
																		label="As required by NSW Government, please declared your current vaccination status by answering the question below:"
																		required={true}
																	/>
																)}
															</Field>
															{formProps.values.vaccinationStatus && formProps.values.vaccinationStatus === 'single' && (
																<Field name="vaccFirstDoseDate">
																	{field => <FormikDatePicker {...field} label="Date Administered" required={true} firstDayOfWeek={DayOfWeek.Monday} />}
																</Field>
															)}
														</>
													)}
												</>
											)}
											<Field name="declaration">
												{field => (
													<FormikCheckbox styles={checkBoxAgreement} {...field} disabled={formProps.isSubmitting} label="I declare that the information I have supplied is true." />
												)}
											</Field>
											<Field name="privacy">
												{field => (
													<FormikCheckbox
														{...field}
														disabled={formProps.isSubmitting}
														onRenderLabel={() => {
															return <span style={{ color: 'rgb(50, 49, 48)', marginLeft: '4px' }} dangerouslySetInnerHTML={{ __html: this.state.privacyStat || '' }}></span>;
														}}
													/>
												)}
											</Field>
										</Stack>
										<Stack tokens={{ childrenGap: 8 }}>
											<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_reCAPTCHA_SITEKEY}>
												<GoogleReCaptcha
													onVerify={token => {
														formProps.setFieldValue('reCaptchaRating', token, true);
													}}
												/>
											</GoogleReCaptchaProvider>

											<Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
												<PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
													Submit
												</PrimaryButton>

												<DefaultButton
													type="button"
													styles={buttonStyle}
													onClick={() => {
														formProps.resetForm();
													}}
													disabled={formProps.isSubmitting}>
													Reset
												</DefaultButton>
											</Stack>
										</Stack>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			/>
		);
	}

	private getContactsForProject = (project: string): any => {
		let _ret: any = { mobiles: [], email: [] };

		const projectMobNumbers: string[] | undefined = this.state.allSites.find(p => p.project === project)?.mobilenumbers;
		const projectEmail: string[] | undefined = this.state.allSites.find(p => p.project === project)?.email;

		if (projectMobNumbers && projectMobNumbers.length > 0) _ret['mobiles'] = projectMobNumbers;
		if (projectEmail && projectEmail.length > 0) _ret['email'] = projectEmail;

		if ((!projectMobNumbers || projectMobNumbers.length < 1) && this.state.defaultSMSNumber && this.state.defaultSMSNumber.length > 0) {
			_ret['mobiles'] = this.state.defaultSMSNumber;
		}

		return _ret;
	};

	private getContactsForSite = (project: string, site: string): any => {
		let _ret: any = { mobiles: [], email: [] };

		const siteMobNumbers: string[] | undefined = this.state.allSites.find(p => p.project === project)?.locations.find(s => s.name === site)?.mobilenumbers;
		const siteEmail: string[] | undefined = this.state.allSites.find(p => p.project === project)?.locations.find(s => s.name === site)?.email;

		if (siteMobNumbers && siteMobNumbers.length > 0) _ret['mobiles'] = siteMobNumbers;
		if (siteEmail && siteEmail.length > 0) _ret['email'] = siteEmail;

		return _ret;
	};

	private renderFinalCheck = (): JSX.Element => {
		const person: CovidForm = this.state.submittedData || new CovidForm();
		return (
			<div className="ms-Grid" dir="ltr">
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-lg12">
						<Stack tokens={{ childrenGap: 8 }} key="CovidFormTitle" verticalFill={true} verticalAlign="center">
							<div style={{ maxWidth: 160 }}>
								<img alt="cpblogo" style={{ width: '100%' }} src={this.state.formLogoUrl}></img>
							</div>
							<div className="formTitle">{this.state.isRAT ? ratTitle : this.state.formTitle}</div>
							{this.state.warning && !this.state.isRAT ? (
								<Label
									styles={{
										root: {
											fontWeight: 'bold'
										}
									}}>
									As you have been in hotspot area, please talk with your supervisor before entering site.
								</Label>
							) : (
								<Label>{this.state.showScreenMessage}</Label>
							)}
							<Stack horizontalAlign="center">
								{this.state.allowed ? (
									this.state.warning ? (
										<img alt="warning" className="confirmationImg" src="https://cpbaueprodpsccdn.azureedge.net/psc/images/warning.png" />
									) : (
										<img alt="granted" className="confirmationImg" src="https://cpbaueprodpsccdn.azureedge.net/psc/images/granted.png" />
									)
								) : (
									<img alt="denied" className="confirmationImg" src="https://cpbaueprodpsccdn.azureedge.net/psc/images/denied.png" />
								)}
								<div className="confirmationDateBox">
									<div className="confirmationDateDayMonth">{moment(person.date).format('D')}</div>
									<div className="confirmationDateMonth">{moment(person.date).format('MMMM')}</div>
									<div className="confirmationDateDayWeek">{moment(person.date).format('dddd')}</div>
									<div className="confirmationDateTime">{moment(person.date).format('LT')}</div>
								</div>
							</Stack>
							<Stack horizontalAlign="start">
								<Label styles={confirmationPersonInfoStyle}>
									<strong>Name:</strong> {person.firstname + ' ' + person.lastname}
								</Label>
								<Label styles={confirmationPersonInfoStyle}>
									<strong>Project:</strong> {person.project}
								</Label>
								{person.location && person.location !== 'none' && (
									<Label styles={confirmationPersonInfoStyle}>
										<strong>Site:</strong> {person.location}
									</Label>
								)}
								{this.state.isRAT ? (
									<>
										{this.state.allowed ? (
											this.state.warning ? (
												<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
													Your test was invalid. You must Re-Test.
												</Label>
											) : (
												<Label styles={confirmationPersonInfoStyle}>Has undertaken a Rapid Antigen Test and the result was NEGATIVE</Label>
											)
										) : (
											<>
												{!this.state.isAffectedWorkerNo ? (
													<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
														Has undertaken a Rapid Antigen Test and the result was POSITIVE
													</Label>
												) : (
													<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
														{affectedWorkedWorningLabel}
													</Label>
												)}
											</>
										)}
									</>
								) : (
									<>
										<Label styles={confirmationPersonInfoStyle}>Mandatory health declaration form completed.</Label>
										{this.state.allowed && !this.state.submittedData?.hasSymptoms ? (
											!this.state.warning ? (
												<div>
													<Label>No flu symptoms declared.</Label>
													<Label>Please proceed and enter site/office.</Label>
												</div>
											) : (
												<>
													{this.state.submittedData?.vaccinationStatus === 'single' && this.state.submittedData?.vaccFirstDoseDate! < threeWeeksAgo && (
														<Label>May need to provide evidence of vaccination.</Label>
													)}
													{this.state.submittedData?.vaccinationStatus === 'single' && this.state.submittedData?.vaccFirstDoseDate! > threeWeeksAgo && (
														<Label>Provide evidence of negative result.</Label>
													)}
													{this.state.submittedData?.vaccinationStatus === 'single' && <Label>Please proceed and enter site/office.</Label>}
												</>
											)
										) : (
											<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
												<ul style={{ paddingInlineStart: '20px' }}>
													<li>You are not allowed to sign on or start work.</li>
													{this.state.submittedData?.vaccinationStatus === 'exemption' ? (
														<li>Claimed exemption from vaccination</li>
													) : (
														<li>Contact a health care professional for advice.</li>
													)}
													<li>Speak to your employer about your working arrangements.</li>
												</ul>
											</Label>
										)}
									</>
								)}
							</Stack>
						</Stack>
					</div>
				</div>
			</div>
		);
	};

	private isPersonAllowed = (values: CovidForm): boolean => {
		if (this.state.isRAT) {
			if (values.rapidAntigenTesting === 'NEGATIVE' || values.rapidAntigenTesting === 'INVALID') {
				return true;
			} else {
				return false;
			}
		} else {
			let extraFields: boolean = this.state.isCPB ? !values.diagnosed && !values.quarantineOrder : true;

			let cpbNswVacc: boolean = false;
			let cpbNswVaccWarn: boolean = false;
			if (this.state.isCPB && values.residentInNSWHODA) {
				if (values.vaccinationStatus === 'none') {
					//allowed
					log(`Not vaccinated.`);
					cpbNswVacc = false;
				} else if (values.vaccinationStatus === 'single' && values.vaccFirstDoseDate! < threeWeeksAgo) {
					log(`single vaccination done more than 3 weeks ago, warning!`);
					cpbNswVacc = true;
					cpbNswVaccWarn = true;
				} else if (values.vaccinationStatus === 'single' && values.vaccFirstDoseDate! > threeWeeksAgo) {
					//allowed
					log(`single vaccination done more than 3 weeks ago, all good!`);
					cpbNswVaccWarn = true;
					cpbNswVacc = true;
				} else if (values.vaccinationStatus === 'exemption') {
					//denied -> ERROR
					log(`exempted from vaccination not allowed.`);

					cpbNswVacc = false;
				} else if (values.vaccinationStatus === 'double') {
					//allowed
					log(`Double vaccinated, all good to go.`);

					cpbNswVacc = true;
				}

				cpbNswVacc = cpbNswVacc && values.permitByServiceNSW;

			} else {
				cpbNswVacc = true;
			}

			if (
				!values.hasSymptoms &&
				!values.inContactWithDiagnosed &&
				!values.travel &&
				(!this.state.contactedByGov || this.state.contactedByGov.length < 1 || !values.contactedByGov) &&
				values.affectedWorker !== 'no' &&
				extraFields &&
				cpbNswVacc
			) {
				log('Person Allowed!');
				if (values.inContactTested || values.visitedHotspot || cpbNswVaccWarn) {
					log('Allowed with Warning');
				}
				return true;
			}
			log('Person Denied!');
		}
		return false;
	};

	private _getErrorMessageForMobile = (value: string): string => {
		return value.match(ausMobilePattern) || !value ? '' : mobileValidationError;
	};

	private _getCustomElementsExampleOne = (): JSX.Element => {
		return (
			<div
				// tslint:disable-next-line:jsx-ban-props
				style={{ display: 'flex' }}>
				<ShimmerElementsGroup
					shimmerElements={[
						{ type: ShimmerElementType.line, width: 40, height: 40 },
						{ type: ShimmerElementType.gap, width: 10, height: 40 }
					]}
				/>
				<ShimmerElementsGroup
					flexWrap={true}
					shimmerElements={[
						{ type: ShimmerElementType.line, width: 300, height: 10 },
						{ type: ShimmerElementType.line, width: 200, height: 10 },
						{ type: ShimmerElementType.gap, width: 100, height: 20 }
					]}
				/>
			</div>
		);
	};

	private _getCustomShimmerElementsThree = (): JSX.Element => {
		return (
			<div
				// tslint:disable-next-line:jsx-ban-props
				style={{ display: 'flex' }}>
				<ShimmerElementsGroup
					width={'90px'}
					shimmerElements={[
						{ type: ShimmerElementType.line, height: 80, width: 80 },
						{ type: ShimmerElementType.gap, width: 10, height: 80 }
					]}
				/>
				<div
					// tslint:disable-next-line:jsx-ban-props
					style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
					<ShimmerElementsGroup
						shimmerElements={[
							{ type: ShimmerElementType.circle, height: 40 },
							{ type: ShimmerElementType.gap, width: 10, height: 40 }
						]}
					/>
					<ShimmerElementsGroup
						flexWrap={true}
						width={'calc(100% - 50px)'}
						shimmerElements={[
							{ type: ShimmerElementType.line, width: '90%', height: 10 },
							{ type: ShimmerElementType.gap, width: '10%', height: 20 },
							{ type: ShimmerElementType.line, width: '100%', height: 10 }
						]}
					/>
					<ShimmerElementsGroup
						flexWrap={true}
						width={'100%'}
						shimmerElements={[
							{ type: ShimmerElementType.line, width: '80%', height: 10, verticalAlign: 'bottom' },
							{ type: ShimmerElementType.gap, width: '20%', height: 20 },
							{ type: ShimmerElementType.line, width: '40%', height: 10, verticalAlign: 'bottom' },
							{ type: ShimmerElementType.gap, width: '2%', height: 20 },
							{ type: ShimmerElementType.line, width: '58%', height: 10, verticalAlign: 'bottom' }
						]}
					/>
				</div>
			</div>
		);
	};
}

export default withRouter(withAITracking(reactPlugin, HealthForm));
